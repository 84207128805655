<template>
  <CRow>
    <CCol lg="12">
      <CCard>
        <CCardHeader tag="div">
          <CIcon name="cil-grid"/>
          Chi tiết người dùng
        </CCardHeader>
        <CCardBody style="min-height: 300px" tag="div">
          <CForm>
            <CRow>
              <CCol lg="6">
                <CInput label="Họ" horizontal :value="detailItem.firstname" readonly/>
              </CCol>
              <CCol lg="6">
                <CInput label="Tên" horizontal :value="detailItem.lastname" readonly/>
              </CCol>
            </CRow>
            <CRow>
              <CCol lg="6">
                <CInput label="Tài khoản" horizontal :value="detailItem.username" readonly/>
              </CCol>
            </CRow>
            <CRow>
              <CCol lg="6">
                <CInput label="Số điện thoại" horizontal :value="detailItem.phone" readonly/>
              </CCol>
              <CCol lg="6">
                <CInput label="Email" horizontal :value="detailItem.email" readonly/>
              </CCol>
            </CRow>
            <CRow>
              <CCol lg="6">
                <CSelect label="Loại đơn vị" horizontal :value.sync="detailItem.loaiDonVi" disabled :options="optionsLoaiDonVi"/>
              </CCol>
              <CCol lg="6">
                <template v-if="detailItem.loaiDonVi === 2 || detailItem.loaiDonVi === 3">
                  <CInput label="Tên đơn vị" horizontal :value="detailItem.donVi_Ten" readonly/>
                </template>
              </CCol>
            </CRow>
            <CRow>
              <CCol lg="6">
                <CInput label="Nhóm quyền" horizontal :value="detailItem.applicationRole && detailItem.applicationRole.name" readonly/>
              </CCol>
              <CCol lg="6"></CCol>
            </CRow>
            <CRow>
              <CCol lg="6">
                <div role="group" class="form-group form-row">
                  <CCol tag="label" sm="3" class="col-form-label">Trạng thái</CCol>
                  <CCol sm="9" class="form-inline">
                    <CInputCheckbox label="Hoạt động" :checked="detailItem.status" disabled/>
                  </CCol>
                </div>
              </CCol>
            </CRow>
          </CForm>
          <CElementCover v-if="isFetchingSingle"/>
        </CCardBody>
        <CCardFooter tag="div" class="text-right">
          <CButton color="secondary" class="mr-2" @click="back">
            <CIcon name="cil-arrow-left"/>
            Quay lại
          </CButton>
          <CButton color="primary" class="mr-2" @click="editItem">
            <CIcon name="cil-pencil"/>
            Sửa
          </CButton>
          <CButton color="danger" @click="isDeleteConfirmationModalOpen = true">
            <CIcon name="cil-x"/>
            Xóa
          </CButton>
        </CCardFooter>
      </CCard>
    </CCol>
    <DeleteConfirmationModal
      title="Xóa người dùng"
      :show.sync="isDeleteConfirmationModalOpen"
      :is-deleting="isDeleting"
      :item="detailItem" @confirm-destroy="confirmDestroy"/>
  </CRow>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import {
  DELETE_APPLICATION_USER,
  GET_APPLICATION_USER
} from '@/store/modules/application-user/actionTypes'
import { enums } from '@/shared/enums'

export default {
  name: 'ApplicationUserDetail',
  data () {
    return {
      isDeleteConfirmationModalOpen: false,
      optionsLoaiDonVi: enums.loaiDonVi
    }
  },
  computed: {
    ...mapGetters('applicationUser', {
      detailItem: 'detailItem',
      isFetchingSingle: 'isFetchingSingle',
      isDeleting: 'isDeleting',
      responseResult: 'responseResult'
    })
  },
  methods: {
    ...mapActions('applicationUser', {
      getItem: GET_APPLICATION_USER,
      deleteItem: DELETE_APPLICATION_USER
    }),
    back () {
      this.$router.push({ path: '/nguoi-dung' })
    },
    editItem () {
      this.$router.push({ path: `/nguoi-dung/${this.detailItem.id}/cap-nhat` })
    },
    async confirmDestroy () {
      await this.deleteItem(this.detailItem)
      if (this.responseResult.error) {
        this.$toast.error(this.responseResult.message)
      } else {
        this.$toast.success(this.responseResult.message)
        this.isDeleteConfirmationModalOpen = false
        await this.$router.push({ path: '/nguoi-dung' })
      }
    }
  },
  async created () {
    await this.getItem(this.$route.params.id)
  }
}
</script>
